import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { API_URL } from '../../../Config/config'
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {

  }
  private handleError(error) {
    switch (error.status) {
      case 422:
        break;
      case 403:
        Swal.fire({
          title: error.statusText,
          text: error.error.messages,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: `OK`,
        })
        break;
      case 401:
        Swal.fire({
          title: `Session expired`,
          text: `You must login`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: `OK`,
        });
        break;
      default:
        Swal.fire({
          title: `${error.statusText}`,
          text: `Contact your administrator`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: `Cancel`,
        })
        break;
    }

    return throwError(error);
  }
  get(model, token) {
    return this.http.get(`${API_URL}/${model}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError));
  }
  get2(model, token) {
    return this.http.get(`${API_URL}/${model}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError)).toPromise();
  }

  download(model, token) {
    return this.http.get(`${API_URL}/${model}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      }), responseType: 'blob' as 'json'
    }).pipe(catchError(this.handleError));
  }

  show(model, id, token) {
    return this.http.get(`${API_URL}/${model}/${id}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError));
  }
  post(model, datos, token) {
    return this.http.post(`${API_URL}/${model}`, datos, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError));
  }
  update(model, id, datos, token) {
    return this.http.put(`${API_URL}/${model}/${id}`, datos, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError));
  }
  delete(model, id, token) {
    return this.http.delete(`${API_URL}/${model}/${id}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError));
  }
  validarRut(cadena) {
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(cadena)) {
      return false
    }
    const tmp = cadena.split('-');
    let digv = tmp[1];
    const rut = tmp[0];
    if (digv == 'K') {
      digv = 'k';
    }
    return (this.verificarDigito(rut) == digv);
  }
  verificarDigito(T) {
    var M = 0, S = 1;
    for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
    return S ? S - 1 : 'k';

  }
  hasPermission(name, user_type) {
    if (user_type === 'iso' || user_type === 'merchant') {
      const per = JSON.parse(localStorage.getItem('permissions')).filter(data => data.name === name)[0];
      if (per !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
