export let API_URL = 'https://themerchantportal.com:8080/api';
export let API_URL_DOC = 'https://themerchantportal.com:8080';
//export let API_URL = 'http://127.0.0.1:8000/api';
//export let API_URL_DOC = 'http://127.0.0.1:8000';
export let Options = {
    iniciadoLogin: false,
    iniciandoLayout: false,
    theme: 'light', // two possible values: light, dark
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    loginImg: null,
    logoImg: null,
    nombreSitio: '',
    avatar: null,
    nombreUsuario: '',
    emailUsuarioL: '',
    tamanoFichero: '',
};
export let FRON_URL = 'https://themerchantportal.com/'
