import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, NgForm, FormArray } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { LoginService } from './service/login.service';
import { TokenService } from './service/token.service';
import { ApiService } from '../views/service/api-service.service';
import { Options } from '../../Config/config'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [LoginService, TokenService]
})
export class LoginComponent implements OnInit {
  msg = '';
  msgRecover = '';
  constructor(private service: LoginService, private Token: TokenService, private routes: Router,
    private apiService: ApiService, private fb: FormBuilder) { }

  loginform = true;
  inicioForm = false;
  changeForm = false;
  isLoading: boolean = false;
  options = Options
  userInicial = '';
  errorMessage: {};
  formularioLogin: FormGroup;
  formularioRecover: FormGroup;
  formularioChange: FormGroup;

  ngOnInit() {
    this.errorMessage = {};
    var url = window.location.toString()
    var param = new URLSearchParams(url)
    if (param.has('user')) {
      this.crearFormularioLogin({ email: param.get('user'), password: '' })
    } else {
      this.crearFormularioLogin({ email: '', password: '' })
    }
    this.crearFormularioRecover({ email: '' })
    this.crearFormularioChange('')


  }


  storeInitialUser(password: string, confirm: string) {
    this.isLoading = true
    const output = this.service.storeUserInicial(this.userInicial, password, confirm).subscribe(
      data => {
        this.Token.handle(data['persona']);
        this.routes.navigate(['/starter']);
      },
      error => {
        this.isLoading = false
        this.errorMessage = <any>error.error.error;
      }
    );
  }
  showRecoverForm() {
    this.crearFormularioLogin({ email: '', password: '' })
    this.crearFormularioRecover({ email: '' })
    this.msgRecover = ''
    this.msg = ''
    this.loginform = !this.loginform;
    this.inicioForm = !this.inicioForm;
  }
  recoverPassword() {
    this.service.recoverPassword(this.formularioRecover.get('email').value)
      .subscribe(
        success => {
          if (success['value'] == 'true') {
            this.showRecoverForm()
          } else {
            this.msgRecover = success['msg'];
          }

        },
        error => {
          if (error.status == 422) {
            let datos = error.error.error
            Object.keys(datos).forEach(prop => {
              const form = this.formularioRecover.get(prop)
              if (form) {
                if (datos[prop].length > 0) {
                  form.setErrors({
                    serverError: datos[prop]
                  })
                }
              }
            })
          }
        }
      )
  }
  check() {
    this.isLoading = true
    var email = this.formularioLogin.get('email').value
    var pass = this.formularioLogin.get('password').value
    const output = this.service.login(email, pass).subscribe(
      data => this.handleResponse(data),
      error => {
        if (error.status == 422) {
          let datos = error.error.error
          Object.keys(datos).forEach(prop => {
            const form = this.formularioLogin.get(prop)
            if (form) {
              if (datos[prop].length > 0) {
                form.setErrors({
                  serverError: datos[prop]
                })
              }
            }
          })
        }
        this.isLoading = false

      }
    );
  }
  handleResponse(data) {
    if (data['value'] == 'true') {

      this.Token.handle(data.datos);
      if (data.datos.user.changePassword == 1) {
        this.crearFormularioChange(data.datos.user.email)
        this.changeForm = true
        this.loginform = false
      } else {
        var url = window.location.toString()
        var param = new URLSearchParams(url)
        if (param.has('linkUser')) {
          this.apiService.get(`notificationsLinkOpen?merchantId=${param.get('merchantId')}&linkUser=${param.get('linkUser')}`, '').subscribe()
        }
        if (param.has('param')) {
          this.routes.navigate([param.get('param')]);
        } else {
          this.routes.navigate(['/starter']);
        }

      }
    } else {
      this.msg = data.msg;
    }
    this.isLoading = false
  }
  changePassword() {
    this.isLoading = true
    var password = this.formularioChange.get('password').value
    var confirm = this.formularioChange.get('confirmar').value
    this.service.changePassword(password, confirm, localStorage.getItem('token'))
      .subscribe(
        success => {
          this.isLoading = false
          this.routes.navigate(['/starter']);
        },
        error => {
          this.isLoading = false
          if (error.status == 422) {
            let datos = error.error.error
            Object.keys(datos).forEach(prop => {
              const form = this.formularioChange.get(prop)
              if (form) {
                if (datos[prop].length > 0) {
                  form.setErrors({
                    serverError: datos[prop]
                  })
                }
              }
            })
          }
        }
      )
  }

  crearFormularioLogin(datos) {
    this.formularioLogin = this.fb.group({
      email: new FormControl(datos.email, Validators.required),
      password: new FormControl(datos.name, Validators.required),
    })
  }
  crearFormularioRecover(datos) {
    this.formularioRecover = this.fb.group({
      email: new FormControl(datos.email, Validators.required),
    })
  }
  crearFormularioChange(email) {
    this.formularioChange = this.fb.group({
      email: new FormControl(email, Validators.required),
      password: new FormControl('', Validators.required),
      confirmar: new FormControl('', Validators.required),
    })
  }
}
