import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { API_URL } from '../../../Config/config'
import Swal from 'sweetalert2'

@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  private handleError(error) {
    switch (error.status) {
      case 422:
        break;
      case 403:
        break;
      case 401:
        Swal.fire({
          title: `Session expired`,
          text: `You must login`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: `OK`,
        })
        break;
      default:
        Swal.fire({
          title: `${error.statusText}`,
          text: `Contact your administrator`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: `Cancel`,
        })
        break;
    }

    return throwError(error)
  }
  login(uname: string, pwd: string) {
    const post = {
      email: uname,
      password: pwd
    }
    return this.http.post(API_URL + '/login', post).pipe(catchError(this.handleError))
  }

  storeUserInicial(user: string, password: string, confirmar: string) {
    const post = {
      email: user,
      password: password,
      confirmar: confirmar,
    }
    return this.http.post(API_URL + '/inicio', post).pipe(catchError(this.handleError))
  }
  verifyEmail(token) {
    return this.http.get(`${API_URL}/email/verify/${token}`, {
    }).pipe(catchError(this.handleError))
  }
  recoverPassword(email) {
    return this.http.post(API_URL + '/recoverPassword', { email: email }).pipe(catchError(this.handleError))
  }
  changePassword(password, confirm, token) {
    let user = JSON.parse(localStorage.getItem('user'))
    return this.http.put(`${API_URL}/updatePassword/${user.id}`, { password: password, confirmar: confirm }, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + token
      })
    }).pipe(catchError(this.handleError))
  }
  logout() {
    localStorage.clear();
  }
}
