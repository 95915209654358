import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'
import { API_URL, Options } from '../../../Config/config'
import Swal from 'sweetalert2'

@Injectable()
export class TokenService {
  private iss = {
    login: 'http://127.0.0.1:8000/api/login',
    signup: 'http://18.204.217.32:8080/api/signup'
  };

  constructor(private http: HttpClient) { }

  private handleError(error) {
    switch (error.status) {
      case 422:
        break;
      case 403:
        break;
      case 401:
        Swal.fire({
          title: `Session expired`,
          text: `You must login`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: `OK`,
        })
        break;
      default:
        Swal.fire({
          title: `${error.statusText}`,
          text: `Contact your administrator`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: `Cancel`,
        })
        break;
    }

    return throwError(error)
  }

  handle(token) {
    this.set(token);
  }

  set(datos) {
    localStorage.setItem('token', datos.token);
    localStorage.setItem('permissions', JSON.stringify(datos.permissions));
    localStorage.setItem('user', JSON.stringify({
      name: datos.user.name,
      email: datos.user.email,
      user_type: datos.user.user_type,
      id: datos.user.id
    }));
  }

  get() {
    return localStorage.getItem('token');
  }

  remove() {
    localStorage.removeItem('token');
  }

  isValid() {
    const token = this.get();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
      }
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }
}
