import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './login/login.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component'
import { AuthGuard } from './auth.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      {
        path: 'views',
        loadChildren: './views/views.module#ViewsModule'
      },
      {
        path: 'views/feeSchedules',
        loadChildren: './views/feeSchedules/feeSchedules.module#FeeSchedulesModule'
      },
      {
        path: 'views/applications',
        loadChildren: './views/applications/applications.module#ApplicationsModule'
      },
      {
        path: 'views/applications/highRiskCommerce',
        loadChildren: './views/applications/ecommerce/ecommerce.module#EcommerceModule'
      },
      {
        path: 'views/applications/transactFirst',
        loadChildren: './views/applications/terminals/terminals.module#TerminalsModule'
      },
      {
        path: 'views/applications/billmybank',
        loadChildren: './views/applications/bill-my-bank/bill-my-bank.module#BillMyBankModule'
      },
      {
        path: 'views/applications/transactFirst/additionalForm',
        loadChildren: './views/applications/terminals/additional-form/additional-form.module#AdditionalFormModule'
      },
      {
        path: 'views/applications/billmybank/additionalForm',
        loadChildren: './views/applications/bill-my-bank/additional-form/additional-form.module#AdditionalFormModule'
      },
      {
        path: 'views/users',
        loadChildren: './views/usuario/usuario.module#UsuarioModule'
      },
      {
        path: 'views/merchants',
        loadChildren: './views/merchants/merchants.module#MerchantModule'
      },
    ]
  },
  {
    path: 'verify/:id',
    component: VerifyEmailComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];
