import { Component, AfterViewInit, OnInit } from '@angular/core';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from '../../../Config/config';
import { ApiService } from '../../views/service/api-service.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  token = localStorage.getItem('token');
  user = JSON.parse(localStorage.getItem('user'));
  permission = [];
  showMenu = '';
  showSubMenu = '';
  options = Options;
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }

  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) { }

  // End open close
  ngOnInit() {

    if (this.user.user_type === 'iso') {
      this.options.sidebarbg = 'skin4';
    } else if (this.user.user_type === 'merchant') {
      this.options.sidebarbg = 'skin6';
    } else {
      this.options.sidebarbg = 'skin5';
    }
    this.apiService.get('config', this.token).subscribe(
      data => {
        Options.tamanoFichero = data['configuration'].postMaxSize
      },
      failure => {
        if (failure.status === 401) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      }
    )
    const route: RouteInfo[] = [
      {
        path: '',
        title: 'MENU',
        icon: 'mdi mdi-dots-horizontal',
        class: 'nav-small-cap',
        extralink: true,
        submenu: [],
        permiso: false
      },
      {
        path: '/starter',
        title: 'Dashboard',
        icon: 'mdi mdi-gauge',
        class: '',
        extralink: false,
        submenu: [],
        permiso: true
      },
      {
        path: '/views/users',
        title: 'Users',
        icon: 'fas fa-user-plus',
        class: '',
        extralink: false,
        submenu: [],
        permiso: this.user != null ? (this.user.user_type === 'admin' || this.user.user_type === 'supervisor' ? true : false) : (false),
      },
      {
        path: '/views/teams',
        title: 'Teams',
        icon: 'fas fa-boxes',
        class: '',
        extralink: false,
        submenu: [],
        permiso: this.user != null ? (this.user.user_type === 'admin' ? true : false) : (false),
      },
      {
        path: '/views/supervisors',
        title: 'Supervisors',
        icon: 'fas fa-user-secret',
        class: '',
        extralink: false,
        submenu: [],
        permiso: this.user != null ? (this.user.user_type === 'admin' ? true : false) : (false),
      },
      {
        path: '/views/isos',
        title: 'ISOs',
        icon: 'fas fa-users',
        class: '',
        extralink: false,
        submenu: [],
        permiso: this.user != null ? (this.user.user_type === 'admin' || this.user.user_type === 'supervisor' ? true : false) : (false),
      },
      {
        path: '/views/merchants',
        title: 'Merchants',
        icon: 'fas fa-user',
        class: '',
        extralink: false,
        submenu: [],
        permiso: this.user != null ? (this.user.user_type !== 'merchant' ? true : false) : (false),
      },
      {
        path: '',
        title: 'Fee Schedules',
        icon: 'mdi mdi-book-multiple',
        class: 'has-arrow',
        extralink: false,
        submenu: [
          {
            path: '/views/feeSchedules/highRiskCommerce',
            title: 'High Risk Commerce',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? this.apiService.hasPermission('feeScheduleEcommerce', this.user.user_type) : false,
          },
          {
            path: '/views/feeSchedules/transactFirst',
            title: 'TransactFirst',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? (this.user.user_type === 'iso' || this.user.user_type === 'merchant' ?
              this.apiService.hasPermission('feeScheduleTerminal', this.user.user_type) : true) : (false),
          },
          {
            path: '/views/feeSchedules/billmybank',
            title: 'Bill My Bank',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? this.apiService.hasPermission('feeScheduleBillMyBank', this.user.user_type) : false,
          },
        ],
        permiso: this.apiService.hasPermission('feeScheduleEcommerce', this.user.user_type) ||
          this.apiService.hasPermission('feeScheduleTerminal', this.user.user_type) ||
          this.apiService.hasPermission('feeScheduleBillMyBank', this.user.user_type) ? true : false
      },
      {
        path: '',
        title: 'Applications',
        icon: 'fas fa-list',
        class: 'has-arrow',
        extralink: false,
        submenu: [
          {
            path: '',
            title: 'High Risk Commerce',
            icon: 'mdi mdi-book-multiple',
            class: 'has-arrow-first-level',
            extralink: false,
            submenu: [
              {
                path: '/views/applications/highRiskCommerce/domestic',
                title: 'Domestic',
                icon: 'mdi mdi-book-multiple',
                class: '',
                extralink: false,
                submenu: [],
                permiso: this.user != null ? this.apiService.hasPermission('applicationEcommerceDomestic', this.user.user_type) : false,
              },
              {
                path: '/views/applications/highRiskCommerce/offshore',
                title: 'Offshore',
                icon: 'mdi mdi-book-multiple',
                class: '',
                extralink: false,
                submenu: [],
                permiso: this.user != null ? this.apiService.hasPermission('applicationEcommerceOffshore', this.user.user_type) : false,
              },
            ],
            permiso: this.apiService.hasPermission('applicationEcommerceDomestic', this.user.user_type) ||
              this.apiService.hasPermission('applicationEcommerceOffshore', this.user.user_type) ? true : false
          },
          {
            path: '/views/applications/transactFirst',
            title: 'TransactFirst',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? (this.user.user_type === 'iso' || this.user.user_type === 'merchant' ?
              this.apiService.hasPermission('applicationTerminal', this.user.user_type) : true) : (false),
          },
          {
            path: '/views/applications/billmybank',
            title: 'Bill My Bank',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? this.apiService.hasPermission('applicationBillMyBank', this.user.user_type) : (false),
          },
          {
            path: '/views/applications/pepperPay',
            title: 'Pepper Pay',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? this.apiService.hasPermission('applicationPinDebit', this.user.user_type) : (false),
          },
          {
            path: '/views/applications/paydek',
            title: 'Paydek',
            icon: 'mdi mdi-book-multiple',
            class: '',
            extralink: false,
            submenu: [],
            permiso: this.user != null ? this.apiService.hasPermission('applicationPaydek', this.user.user_type) : (false),
          },
        ],
        permiso: this.user != null ? (this.apiService.hasPermission('applicationTerminal', this.user.user_type) ||
          this.apiService.hasPermission('applicationBillMyBank', this.user.user_type) ||
          this.apiService.hasPermission('applicationPaydek', this.user.user_type) ||
          this.apiService.hasPermission('applicationPinDebit', this.user.user_type) ||
          this.apiService.hasPermission('applicationEcommerceDomestic', this.user.user_type) ||
          this.apiService.hasPermission('applicationEcommerceOffshore', this.user.user_type) ? true : false)
          : false,
      },
    ];
    this.sidebarnavItems = route.filter(sidebarnavItem => sidebarnavItem);
  }


}
