import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { Options } from '../../../Config/config'
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { LoginService } from '../../login/service/login.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  providers: [LoginService]
})
export class NavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  options = Options
  user = JSON.parse(localStorage.getItem('user'))
  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  constructor(private modalService: NgbModal, private service: LoginService, private routes: Router) { 
    this.options.avatar = this.user.avatar
    this.options.nombreUsuario = this.user.name 
    this.options.emailUsuarioL = this.user.email
  }


  // This is for Notifications



  logout() {    
    this.service.logout()
    this.routes.navigate(['/login']);
  }
  ngAfterViewInit() { }
}
