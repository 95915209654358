import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/service/login.service';
import { TokenService } from '../login/service/token.service';
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css'],
  providers: [LoginService, TokenService]
})
export class VerifyEmailComponent implements OnInit {
  msg = '';
  isLoading: boolean = true;
  style: string = ''
  result: boolean = true
  constructor(private service: LoginService, private Token: TokenService, private activeRouter: ActivatedRoute, private routes: Router ) { }

  ngOnInit() {
    this.verifyEmail()
  }

  verifyEmail() {
    this.isLoading = true
    this.service.verifyEmail(this.activeRouter.snapshot.params.id
    ).subscribe(
      success => {
        this.msg = success['message']
        this.isLoading = false
        this.style = success['value'] != true ? 'alert alert-danger' : 'alert alert-success'
        if (success['value']) {
          this.Token.handle(success['datos']);
          setTimeout(() => {this.routes.navigate(['/starter']);},500)
        }else{
          this.result = false
        }
      },
      failure => {

      }
      )
  }

  goToLogin(){
    this.routes.navigate(['/login'])
  }

}
